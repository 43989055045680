import { NavigationGuard } from 'vue-router';

import AccountsStore from '@/store/accounts';
import { hasAmplitudeInitialized, userEvents } from '@/plugins/user-events';
import { getHackyPinia } from '@/utils/getHackyPinia';
import { ExperimentMeta } from '@/types/experiments';
import { NOT_FOUND } from '@/utils/router/namedRoutes';
import { useExperimentsStore } from '@/store/experiments';
import { hasExperimentMeta } from '@/utils/router/metaRouteGuard';

export let initialized = false;

/**
 * Initializes the Amplitude SDK. Sets the user state required by the `amplitude-js` and
 * `@amplitude/experiment-js-client` packages. This is used to determine what feature flags
 * the user has access to.
 *
 * Order of execution:
 * 1. Amplitude SDK client is initialized -> new Amplitude()
 * 2. Amplitude user is identified (user id and user attributess) -> Amplitude.setupIdentity()
 * 3. Amplitude user access level is set -> initializeAmplitude()
 * 4. Amplitude Experiments SDK is initialized -> checkAmplitude() -> useExperimentsStore()
 * 5. Amplitude Experiments are fetched -> checkAmplitude() -> fetchExperiments()
 */
export const initializeAmplitude = async (): Promise<void> => {
  if (initialized) {
    return;
  }

  try {
    // Check that we have sent in the logged in user data which is required by Experiments
    if (!(await hasAmplitudeInitialized)) {
      return;
    }

    const accessLevel = AccountsStore.user.is_owner ? 'owner' : AccountsStore.user.role.toLocaleLowerCase();

    // Set additional user status attributes on Amplitude session here (this may or may not need to be awaited, depending on our user targeting needs with Amplitude Experiments).
    userEvents.addUserAttributes({
      'access-level': { value: accessLevel },
    });
  } catch (error) {
  } finally {
    initialized = true;
  }
};

export const checkAmplitude: NavigationGuard = async (to, from, next) => {
  await initializeAmplitude();

  const pinia = await getHackyPinia();

  const { fetchExperiments, useExperiment } = useExperimentsStore(pinia);

  // Cached by default (`overwrite` param) after the initial call.
  await fetchExperiments();

  /*
  Check if the navigation contains a valid instance of ExperimentMeta,
    if not we can continue the navigation without checking Variant Values.
  */
  if (!hasExperimentMeta(to.meta)) {
    return next();
  }

  const { name, type, control, allowed }: ExperimentMeta = to.meta.experiment;
  const { variant, isVariant } = useExperiment(name, type);

  const isValidOn = isVariant('on').value;
  const isValidExperimentOrFF = variant.value.value && allowed.includes(variant.value.value);

  if (!(isValidOn || isValidExperimentOrFF)) {
    if (control) {
      /**
       * To allow redirecting out of our Vue app control can be either:
       * - a string to be used as a url
       * - a Vue Router Location
       */
      if (typeof control === 'string') {
        return (window.location.href = control);
      } else {
        return next(control);
      }
    } else {
      return next({ name: NOT_FOUND });
    }
  }

  return next();
};
