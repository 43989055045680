import Vue, { ref, computed } from 'vue';
import { Route } from 'vue-router';

import AccountsStore from '@/store/accounts';
import { UserStatus } from '@/utils/decodeUserStatus';

import ProfilesStore from '@/store/profiles';

import { FeatureFlags, isFlagActive } from '@/utils/feature-flags';

import {
  ACCOUNT_SETUP_UPDATED_TERMS,
  ACCOUNT_SETUP_TERMS,
  ACCOUNT_SETUP_DETAILS,
  ACCOUNT_SETUP_PLATFORM,
  ACCOUNT_SETUP_CREATE_SHOP,
  ACCOUNT_SETUP_DONE,
} from '@/utils/router/namedRoutes';

export {
  ACCOUNT_SETUP_UPDATED_TERMS,
  ACCOUNT_SETUP_TERMS,
  ACCOUNT_SETUP_DETAILS,
  ACCOUNT_SETUP_PLATFORM,
  ACCOUNT_SETUP_CREATE_SHOP,
  ACCOUNT_SETUP_DONE,
} from '@/utils/router/namedRoutes';

export type SetupStepRoute =
  | typeof ACCOUNT_SETUP_UPDATED_TERMS
  | typeof ACCOUNT_SETUP_TERMS
  | typeof ACCOUNT_SETUP_DETAILS
  | typeof ACCOUNT_SETUP_PLATFORM
  | typeof ACCOUNT_SETUP_CREATE_SHOP
  | typeof ACCOUNT_SETUP_DONE;

// TODO: Add metadata, i.e. tags or labels for the Modes to support grouping by feature such as "Solicitation", "Onboarding", "Other".
export type Mode = 'OWNER_SETUP' | 'CREATE_LTK_SHOP' | 'CONTRIBUTOR_SETUP' | 'ACCEPT_UPDATED_TERMS' | 'DEFAULT';

/**
 * List of steps for different Account Setup purposes. Each Account Setup mode consists of a different user flow for a specific purpose (onboarding/setup, TOS updates, etc.).
 */
function getListOfSteps(): Readonly<Record<Mode, SetupStepRoute[]>> {
  return {
    OWNER_SETUP: [
      ACCOUNT_SETUP_TERMS,
      ACCOUNT_SETUP_DETAILS,
      ACCOUNT_SETUP_PLATFORM,
      ACCOUNT_SETUP_CREATE_SHOP,
      ACCOUNT_SETUP_DONE,
    ],
    CREATE_LTK_SHOP: [ACCOUNT_SETUP_CREATE_SHOP, ACCOUNT_SETUP_DONE],
    CONTRIBUTOR_SETUP: [ACCOUNT_SETUP_TERMS, ACCOUNT_SETUP_DETAILS, ACCOUNT_SETUP_DONE],
    ACCEPT_UPDATED_TERMS: [ACCOUNT_SETUP_UPDATED_TERMS],
    DEFAULT: [ACCOUNT_SETUP_DONE],
  };
}

export type ModeOptions = {
  country: string;
  isOwner: boolean;
  needsToAcceptTerms: boolean;
  hasCompleteStatus: boolean;
  hasProfile: boolean | undefined;
};

export function getMode({ country, isOwner, needsToAcceptTerms, hasCompleteStatus, hasProfile }: ModeOptions): Mode {
  let mode: Mode = 'DEFAULT';

  // Solicitation
  if (hasCompleteStatus) {
    if (needsToAcceptTerms) {
      mode = 'ACCEPT_UPDATED_TERMS';
      // TODO: removing solitation so we don't hijack current users
      // } else if (isOwner && !hasProfile) {
      //   mode = 'CREATE_LTK_SHOP';
    }
  }
  // Onboarding
  else {
    if (isOwner) {
      mode = 'OWNER_SETUP';
    } else {
      mode = 'CONTRIBUTOR_SETUP';
    }
  }

  return mode;
}

export function useAccountSetupWizard(route: Readonly<Route>) {
  const mode = ref<Mode>('DEFAULT');

  const fetchData = Promise.all([
    AccountsStore.fetchTermsData(),
    AccountsStore.fetchAccount(),
    AccountsStore.fetchAccountUser({ overwrite: true }),
    ProfilesStore.fetchProfile({ overwrite: true }),
  ]);

  /**
   * Promise for tracking composable initialization state.
   * Sets the Mode (only) once to avoid state changes during Account Setup navigation.
   */
  const hasInit = fetchData
    .then(function setMode([fetchTerms, fetchAccount, fetchAccountUser, hasProfile]) {
      mode.value = getMode({
        country: AccountsStore.user.address.country,
        isOwner: AccountsStore.user.is_owner,
        needsToAcceptTerms: AccountsStore.user.needs_accept_terms,
        hasCompleteStatus: AccountsStore.userStatusRoles.includes(UserStatus.COMPLETE),
        hasProfile,
      });
    })
    .then(() => Vue.nextTick());

  /**
   * Data properties
   */

  const steps = computed<SetupStepRoute[]>(() => {
    return getListOfSteps()[mode.value];
  });

  const current = computed<SetupStepRoute | undefined>(() => {
    const name = route.name;
    if (steps.value.findIndex((s) => s === name) < 0) return undefined;
    return name as SetupStepRoute;
  });

  const next = computed<SetupStepRoute | undefined>(() => {
    if (!current.value) return undefined;
    const currentIndex = steps.value.findIndex((s) => s === current.value);
    const next: SetupStepRoute | undefined = steps.value[currentIndex + 1];
    if (!next) return undefined;
    return next;
  });

  const previous = computed<SetupStepRoute | undefined>(() => {
    if (!current.value) return undefined;
    const currentIndex = steps.value.findIndex((s) => s === current.value);
    const prev: SetupStepRoute | undefined = steps.value[currentIndex - 1];
    if (!prev) return undefined;
    return prev;
  });

  /**
   * UI properties
   */

  const hideBackBtn = computed<boolean>(() => {
    if (!current.value) return true;

    switch (mode.value) {
      case 'OWNER_SETUP':
        return [ACCOUNT_SETUP_TERMS, ACCOUNT_SETUP_DETAILS].includes(current.value);
      case 'CREATE_LTK_SHOP':
        return [ACCOUNT_SETUP_CREATE_SHOP].includes(current.value);
      case 'CONTRIBUTOR_SETUP':
        return [ACCOUNT_SETUP_TERMS, ACCOUNT_SETUP_DETAILS].includes(current.value);
      case 'ACCEPT_UPDATED_TERMS':
      case 'DEFAULT':
      default:
        return true;
    }
  });

  const hiddenSteps: SetupStepRoute[] = [ACCOUNT_SETUP_TERMS, ACCOUNT_SETUP_UPDATED_TERMS, ACCOUNT_SETUP_DONE];

  const hideStepper = computed<boolean>(() => {
    if (!current.value) return true;
    // Hide stepper if there is no more than one display step
    if (displaySteps.value.length <= 1) return true;

    return hiddenSteps.includes(current.value);
  });

  const displaySteps = computed<SetupStepRoute[]>(() => {
    // Always return a non-empty list of steps for rendering with the Stepper component
    switch (mode.value) {
      case 'OWNER_SETUP':
      case 'CONTRIBUTOR_SETUP':
      case 'CREATE_LTK_SHOP':
        return steps.value.filter((s) => !hiddenSteps.includes(s));
      case 'ACCEPT_UPDATED_TERMS':
      case 'DEFAULT':
      default:
        return steps.value.map((s) => s);
    }
  });

  const displayCurrentIndex = computed<number>(() => displaySteps.value.findIndex((s) => s === current.value));

  return {
    hasInit,
    mode,
    steps,
    current,
    next,
    previous,
    hideBackBtn,
    hideStepper,
    displaySteps,
    displayCurrentIndex,
  };
}
