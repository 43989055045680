import { defineStore } from 'pinia';
import { MediaUploadMap, MediaObjectProgressMap, UploadProgress } from '@/types/ltks';
import { computed, ref } from 'vue';
import { FileWithImageData } from '@/types/ltks';

export const useMediaObjectsStore = defineStore('Media Objects', () => {
  // Initial State
  const mediaObjectProgress = ref<MediaObjectProgressMap>({});
  const mediaUploads = ref<MediaUploadMap>({});
  const successfulMediaUploads = ref<(string | File | undefined)[]>([]);

  const inProcessImageURL = ref<string | undefined>(undefined);
  const posterFile = ref<File | null>(null);
  const processedImageURL = ref<string | undefined>(undefined);
  const selectedImage = ref<File | null>(null);
  const selectedImageURL = ref<string | undefined>(undefined);
  const selectedVideo = ref<string>('');
  const selectedVideoFileList = ref<FileList | null>(null);
  const selectedVideoPoster = ref<string | undefined>(undefined);
  const videoFile = ref<File | null>(null);

  const setMediaObjectProgress = (ltkId: string, mediaObject: UploadProgress) => {
    mediaObjectProgress.value = Object.assign({}, mediaObjectProgress.value, {
      [ltkId]: { ...mediaObject },
    });
  };

  const getMediaObjectProgressByLtkId = (ltkId: string) => {
    return computed(() => mediaObjectProgress.value[ltkId]);
  };

  const getUriByLtkId = (ltkId: string) => {
    return computed(() => mediaUploads.value[ltkId]);
  };

  const $reset = () => {
    mediaObjectProgress.value = {};
    mediaUploads.value = {};
    successfulMediaUploads.value = [];
  };

  const handleVideo = async (file: File) => {
    return URL.createObjectURL(file);
  };

  const setMediaUploads = async (ltkId: string, file: File) => {
    const mediaUpload = file.type.split('/')[0] === 'video' ? file : await handleImage(file);
    mediaUploads.value = Object.assign({}, mediaUploads.value, {
      [ltkId]: [mediaUpload],
    });
  };

  const setSuccessfulMediaUploads = (media: string | File | undefined) => {
    if (!media) return;
    successfulMediaUploads.value?.push(media);
  };

  const handleImage = async (file: File) => {
    const reader = new FileReader();
    await new Promise((res, rej) => {
      reader.addEventListener('load', res);
      reader.addEventListener('error', rej);
      reader.readAsDataURL(file);
    });
    return (reader.result as string | null) || undefined;
  };

  const addImageData = async (files: File[]): Promise<FileWithImageData[]> => {
    const filesWithImages = await Promise.all(
      files.map(async (file) => {
        let imageData;
        if (file.type.split('/')[0] === 'video') {
          imageData = (await handleVideo(file)) || '';
        } else {
          imageData = (await handleImage(file)) || '';
        }

        // Explicitly create a new File object, rather than using spread operator to support typing
        const newFile = new File([file], file.name, {
          type: file.type,
          lastModified: file.lastModified,
        });
        const fileWithImageData: FileWithImageData = Object.assign(newFile, { imageData });

        return fileWithImageData;
      }),
    );
    return filesWithImages;
  };
  // Desktop Add Media
  const setSelectedImage = async (image: File) => {
    selectedImageURL.value = await handleImage(image);
    selectedImage.value = image;
    setInProcessImage(selectedImageURL.value);
  };

  const setInProcessImage = (imgUrl: string | undefined) => {
    inProcessImageURL.value = imgUrl;
  };

  const setProcessedImage = (imageUrl: string | undefined) => {
    processedImageURL.value = imageUrl;
  };
  const setVideo = async (file: File) => {
    videoFile.value = file;
    if (selectedVideo.value) {
      URL.revokeObjectURL(selectedVideo.value);
    }
    selectedVideo.value = await handleVideo(file);
  };
  const setRawVideoFile = async (files: FileList) => {
    selectedVideoFileList.value = files;
  };

  const setSelectedVideo = (url: string) => {
    selectedVideo.value = url;
  };
  const setVideoPoster = async (file: File) => {
    if (!file) return;
    posterFile.value = file;
    selectedVideoPoster.value = await handleImage(file);
  };
  const resetImage = () => {
    processedImageURL.value = undefined;
    inProcessImageURL.value = undefined;
    selectedImage.value = null;
    selectedImageURL.value = undefined;
  };
  const resetVideo = () => {
    videoFile.value = null;
    selectedVideoPoster.value = undefined;
    selectedVideo.value = '';
    selectedVideoFileList.value = null;
  };

  const updateVideo = (videoUri: string, imageUri: string) => {
    selectedVideo.value = videoUri;
    selectedVideoPoster.value = imageUri;
  };

  return {
    inProcessImageURL,
    mediaObjectProgress,
    mediaUploads,
    posterFile,
    processedImageURL,
    selectedImage,
    selectedImageURL,
    selectedVideo,
    selectedVideoPoster,
    successfulMediaUploads,
    videoFile,
    selectedVideoFileList,
    $reset,
    addImageData,
    getMediaObjectProgressByLtkId,
    getUriByLtkId,
    handleImage,
    handleVideo,
    resetImage,
    resetVideo,
    setInProcessImage,
    setMediaObjectProgress,
    setMediaUploads,
    setProcessedImage,
    setRawVideoFile,
    setSelectedImage,
    setSelectedVideo,
    setSuccessfulMediaUploads,
    setVideo,
    setVideoPoster,
    updateVideo,
  };
});
