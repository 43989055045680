import { NavigationGuard, Location } from 'vue-router';
import { auth } from '@/utils/auth';

export const checkAuthentication: NavigationGuard = async function (to, from, next) {
  // Auth is evaluated on a per route basis (required by default)
  const isLoggedIn = await auth.isAuthenticated();

  const requiresAuth: boolean | undefined = to.meta?.auth?.required ?? true;

  if (requiresAuth === isLoggedIn) return next();

  const redirectLocation: Location | undefined = to.meta?.auth?.mismatchedRedirect;

  // Redirect on explicit mismatch
  if (redirectLocation) {
    redirectLocation.query = to.query;
    redirectLocation.hash = to.hash;
    return next(redirectLocation);
  }

  // Unauthenticated user: Hard redirect to Sigil Login
  if (!isLoggedIn) {
    return auth.login({
      options: {
        appState: {
          target: to.fullPath,
        },
      },
      next: to.fullPath,
    });
  }

  return next();
};
