
import type { FocusTargetOrFalse, FocusTargetValue } from 'focus-trap';
import type { Ref } from 'vue';
import { computed, defineComponent, nextTick, onUnmounted, ref, watchEffect } from 'vue';
import { Portal } from '@linusborg/vue-simple-portal';
import { RwFocusTrap } from '../RwFocusTrap';
import RwOverlay from './RwOverlay.vue';
import { uuid, scrollLock } from '../../utils';

export default defineComponent({
  name: 'RwModal',
  components: { RwFocusTrap, RwOverlay, Portal },
  model: {
    prop: 'active',
    event: 'update:active',
  },
  props: {
    id: {
      type: String,
      default: () => `rw--modal-${uuid()}`,
    },
    customClass: {
      type: String,
      default: '',
    },
    portalTo: {
      type: String,
      default: '#modal-portal',
    },
    portalDisabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    backdrop: {
      type: Boolean,
      default: true,
    },
    backdropClass: {
      type: String,
      default: 'rw--modal__overlay',
    },
    backdropBlur: {
      type: Boolean,
      default: false,
    },
    backdropTransparent: {
      type: Boolean,
      default: false,
    },
    clickOutsideToClose: {
      type: Boolean,
      default: true,
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: 'md',
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    aside: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'right',
    },
    width: {
      type: [String, Number],
    },
    height: {
      type: [String, Number],
    },
    sheet: {
      type: Boolean,
      default: false,
    },
    stage: {
      type: Number,
      default: 1,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['outside-click', 'opened', 'closed', 'update:active'],
  setup(props, { emit }) {
    const modalRef = ref<HTMLElement | null>(null) as Ref;
    const modalWrapperRef = ref<HTMLElement | null>(null);
    const trapActive = ref(false);
    const lastFocusedElement = ref<FocusTargetOrFalse | undefined>(undefined);
    const classes = computed(() => {
      const sizeClass = `is--${props.size}`;
      const asidePlacement = `is--aside--${props.placement}`;
      return {
        'is--fullscreen': props.fullscreen && !props.aside,
        'is--aside': props.aside,
        'is--aside--fullscreen': props.aside && props.fullscreen,
        'is--sheet': props.sheet,
        [asidePlacement]: props.aside && props.placement,
        [sizeClass]: true,
        'is--default': props.stage === 1,
        'is--half': props.stage === 0,
        'is--dark': props.dark,
      };
    });

    const setFocus = () => {
      window.setTimeout(() => {
        if (modalRef.value) {
          modalRef.value.setAttribute('tabindex', '-1');
          modalRef.value.focus();
        }
      }, 0);
    };
    const computedStyles = computed(() => {
      return {
        maxWidth: props.width ? `${props.width}px` : '',
        maxHeight: props.height ? `${props.height}px` : '',
      };
    });

    watchEffect(
      async () => {
        if (modalRef.value instanceof HTMLElement) {
          if (props.active) {
            lastFocusedElement.value = document.activeElement as FocusTargetValue;
            emit('update:active', props.active);
          }
          await nextTick();
          scrollLock(props.id, props.active);
          if (props.active) {
            setFocus();
          }
        }
      },
      { flush: 'post' },
    );

    onUnmounted(() => {
      // Ensure that if this component is removed, the scroll lock is released.
      // Watcher may not fire a final time depending how how the card is removed
      scrollLock(props.id, false);
    });

    const onClose = async () => {
      trapActive.value = false;
      await nextTick();
      emit('update:active', false);
    };

    const onOutsideClick = () => {
      if (props.clickOutsideToClose) onClose();
      emit('outside-click');
    };

    const onEsc = () => {
      if (props.closeOnEsc) onClose();
    };

    const dispatchEvents = async (state: string) => {
      await nextTick();
      window.dispatchEvent(new Event('resize'));
      if (state === 'enter') {
        emit('opened');
        trapActive.value = true;
      }
      if (state === 'leave') emit('closed');
    };

    return {
      classes,
      modalRef,
      modalWrapperRef,
      trapActive,
      onOutsideClick,
      onEsc,
      dispatchEvents,
      lastFocusedElement,
      computedStyles,
    };
  },
});
