import { BrowserTracker, newTracker, trackSelfDescribingEvent } from '@snowplow/browser-tracker';
import { TrackerConfiguration } from '@snowplow/browser-tracker-core';
import { ImpressionEvent } from './types';
import { auth } from '@/utils/auth';

export default class Snowplow {
  private tracker: BrowserTracker;

  constructor({
    userId,
    server,
    trackerName = 'sp',
    trackerConfig,
  }: {
    userId?: string;
    server: string;
    trackerName?: string;
    trackerConfig: TrackerConfiguration;
  }) {
    this.tracker = newTracker(trackerName, server, trackerConfig);

    if (userId) {
      this.tracker.setUserId(userId);
    }
  }

  public async init(): Promise<boolean> {
    try {
      const idTokenClaims = await auth.getIdTokenClaims();

      if (idTokenClaims?.account_id) {
        this.identifyUser(idTokenClaims.account_id);
      }
      return true;
    } catch {
      return false;
    }
  }

  public identifyUser(userId: number) {
    this.tracker.setUserId(userId.toString());
  }

  public trackEvent(payload: ImpressionEvent): void {
    trackSelfDescribingEvent(payload);
  }
}
