import { processResponse, Api } from '@/utils/api';
import {
  AccountResponse,
  AccountUserContact,
  AccountUsersResponse,
  UserResponse,
  ChangeEmailResponse,
  Account,
  AccountUser,
  TermsBody,
  TermsResponse,
} from '@/store/accounts/types';
import { auth } from '@/utils/auth';

const config = window.rewardStyle;

class AccountsApi extends Api {
  public apiBaseUrl = `${config.API_GATEWAY}/api/creator-account-service/v1/`;

  private get accessId() {
    return auth.getIdTokenClaims().then((claims) => claims?.__raw);
  }

  private get accountId() {
    return auth.getIdTokenClaims().then((claims) => claims?.account_id?.toString());
  }

  private get userId() {
    return auth.getIdTokenClaims().then((claims) => claims?.publisher_id?.toString());
  }

  public async getAccount() {
    const response = await this.sendRequest('GET', `accounts/${await this.accountId}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<AccountResponse>(response);
  }

  public async patchAccount(accountData: Partial<Account>) {
    const body = JSON.stringify(accountData);
    const response = await this.sendRequest('PATCH', `accounts/${await this.accountId}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
      body,
    });

    return processResponse<AccountResponse>(response);
  }

  public async getUser() {
    const response = await this.sendRequest('GET', `users/${await this.userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<UserResponse>(response);
  }

  public async patchUser(userData: Partial<AccountUser>, userId: string) {
    const body = JSON.stringify(userData);
    const response = await this.sendRequest('PATCH', `users/${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
      body,
    });

    return processResponse<UserResponse>(response);
  }

  public async getAccountUsers() {
    const response = await this.sendRequest('GET', `accounts/${await this.accountId}/users`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<AccountUsersResponse>(response);
  }

  public async postAccountUser(newUser: Partial<AccountUser>) {
    const body = JSON.stringify(newUser);
    const response = await this.sendRequest('POST', `accounts/${await this.accountId}/users`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
      body,
    });

    return processResponse<UserResponse>(response);
  }

  public async changeEmail(token: string) {
    const response = await this.sendRequest('GET', `change/email/${token}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<ChangeEmailResponse>(response);
  }

  public async confirmChangeEmail(token: string) {
    const response = await this.sendRequest('PUT', `change/email/${token}`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<ChangeEmailResponse>(response);
  }

  public async getAcceptTerms() {
    const response = await this.sendRequest('GET', `terms/accept`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<TermsResponse>(response);
  }

  public async postAcceptTerms(body: TermsBody) {
    const response = await this.sendRequest('POST', `terms/accept`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
      body: JSON.stringify(body),
    });

    return processResponse<TermsResponse>(response);
  }

  public async putStatusComplete() {
    const response = await this.sendRequest('PUT', `users/${await this.userId}/status/complete`, {
      headers: {
        'Content-Type': 'application/json',
        'x-id-token': (await this.accessId) || '',
      },
    });

    return processResponse<UserResponse>(response);
  }
}

export default new AccountsApi();
