import Vue from 'vue';

import { PiniaVuePlugin, createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

Vue.use(PiniaVuePlugin);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

import store from './store';

import registerPlugins from '@/plugins';
registerPlugins(Vue);

import router from '@/router';

import App from '@/App.vue';

new Vue({
  pinia,
  router,
  store,
  render: (h) => h(App),
}).$mount('#rewardstyle');
