import { MissingRefreshTokenError } from '@auth0/auth0-spa-js';
import { auth } from '@/utils/auth';

export async function getAccessTokenSafely() {
  try {
    return await auth.getAccessToken();
  } catch (error: unknown) {
    // auth0-spa-js will throw a MissingRefreshToken error if no token is found,
    // but even with Sigil we return(ed) null from cookie check, so let's not break behavior
    if ((error as MissingRefreshTokenError)?.error === 'missing_refresh_token') {
      return null;
    }

    throw error;
  }
}

export default class Api {
  public apiBaseUrl = 'https://localhost/';

  public async sendRequest(method: string, path: string, options: RequestInit = {}) {
    const token = await getAccessTokenSafely();

    const { mode = 'cors', headers = { 'Content-Type': 'application/json' }, ...requestOptions } = options;

    return await fetch(
      `${this.apiBaseUrl}${path}`,
      Object.assign(
        {
          method,
          mode,
          headers: token ? Object.assign({ Authorization: `Bearer ${token}` }, headers) : headers,
        },
        requestOptions,
      ),
    );
  }
}
