import { NavigationGuard } from 'vue-router';
import { auth } from '@/utils/auth';

/**
 * @description A higher-order function for conditional navigation guard usage. Will call your navigation guard if the user is authenticated. Will call next() to move to the next navigation guard otherwise.
 */
export const isUserAuthenticated: (f: NavigationGuard) => NavigationGuard = (f) => (to, from, next) => {
  auth.isAuthenticated().then((isLoggedIn: boolean) => {
    isLoggedIn ? f(to, from, next) : next();
  });
};
