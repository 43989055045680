import { NavigationGuard } from 'vue-router';
import { APPLY_HOME } from '@/utils/router/namedRoutes';
import { useSessionStorage } from '@vueuse/core';
import { SessionStorage } from '@/utils/sessionStorage';

export const checkApplicationComplete: NavigationGuard = async function (to, from, next) {
  const hasCompletedApplication = useSessionStorage(SessionStorage.RS_APPLICATION_COMPLETE, false);

  if (hasCompletedApplication.value) {
    return next({ name: APPLY_HOME, query: to.query });
  }

  return next();
};
