
import { defineComponent, computed } from 'vue';

import { Layout } from '@/layouts/types';

import SiteHeaderApplication from '@/components/SiteHeaderApplication.vue';
import ApplyToBeCreatorBanner from '@/components/ApplyToBeCreatorBanner.vue';
import { useRoute } from 'vue-router/composables';
import { APPLY_HOME, type ApplicationPages } from '@/utils/router/namedRoutes';
import { applicationSignupImageSet } from '@/rs-application/utils/applicationSignupImageSet';

export default defineComponent({
  name: Layout.APPLICATION,
  components: {
    SiteHeaderApplication,
    ApplyToBeCreatorBanner,
  },
  setup() {
    const $route = useRoute();
    const routeName = computed(() => {
      if ($route.name && $route.name in applicationSignupImageSet) {
        return $route.name;
      } else {
        return APPLY_HOME;
      }
    });
    const hideApplicationBanner = computed(
      () => applicationSignupImageSet[routeName.value as ApplicationPages].hideApplyBanner,
    );

    return { hideApplicationBanner };
  },
});
