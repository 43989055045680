import { RouteConfig } from 'vue-router';
import { FOLDERS } from '@/utils/router/namedRoutes';
import { uniqueId } from '@/utils/uniqueId';
import foldersStore from '@/store/folders/index';
import { gettext } from '@/utils/fakeGetText';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';

/*
  Documentation of using gettext without Vue: https://github.com/Polyconseil/vue-gettext#usage-of-translate-without-vue
  Note: Gives warning of no exported member
*/

const routes: RouteConfig[] = [
  {
    path: '/products',
    beforeEnter: async (to, from, next) => {
      await validateRoute([], to, from, next);
    },
    component: () => import(/* webpackChunkName: "products" */ '../FoldersShell.vue'),
    children: [
      {
        path: '',
        redirect: '/products/my-folders',
        name: FOLDERS,
      },
      {
        path: 'my-folders',
        component: () => import(/* webpackChunkName: "my-folders" */ '../views/Folders.vue'),
        meta: {
          breadcrumbs: [
            { label: gettext('Products'), to: '/products', id: uniqueId() },
            { label: gettext('My folders'), to: '/products/my-folders', id: uniqueId() },
          ],
        },
      },
      {
        path: 'my-folders/all-saved',
        component: () => import(/* webpackChunkName: "all saved products" */ '../views/AllSaved.vue'),
        meta: {
          breadcrumbs: [
            { label: gettext('Products'), to: '/products', id: uniqueId() },
            { label: gettext('My folders'), to: '/products/my-folders', id: uniqueId() },
            { label: gettext('All Saved'), to: '/products/my-folders/all-saved', id: uniqueId() },
          ],
        },
      },
      {
        path: 'my-folders/:id',
        component: () => import(/* webpackChunkName: "folder" */ '../views/Folder.vue'),
        props: { id: true },
        // meta is still a function that is accepting no arguements, the 'this' in this instance is a way
        // of letting typescript know we specifically want to invoke the method with a 'this' of type Route.
        meta: {
          /*
            This will possibly need to be refactored to a computed value to account for edge case:
            If the app language changes, the label values won't be updated with the translations for the new language until the user refreshes the app or navigates elsewhere and then back to this page
          */
          breadcrumbs() {
            const name = foldersStore.getFolderNameById(this.params.id) ?? '';
            return [
              { label: gettext('Products'), to: '/products', id: uniqueId() },
              { label: gettext('My folders'), to: '/products/my-folders', id: uniqueId() },
              { label: name, to: `/products/my-folders/${this.params.id}`, id: uniqueId() },
            ];
          },
        },
      },
    ],
  },
];

export default routes;
