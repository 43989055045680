import { computed, watch } from 'vue';
import legacyHomePageLink from '@/utils/legacyHomePageLink';
import userStatus from '@/utils/userStatus';
import { NavigationItem } from '@/lib/models/NavigationItem';
import { uniqueId } from '@/utils/uniqueId';
import AccountsStore from '@/store/accounts';
import ProfilesStore from '@/store/profiles';
import { useAboutYouContent } from '@/rs-account/composables/useAboutYouContent';
import { auth } from '@/utils/auth';
import { computedAsync } from '@vueuse/core';
import { useExperimentsStore } from '@/store/experiments';
import { ExperimentType, ExperimentName, ExperimentMeta } from '@/types/experiments';

const { HELP_CENTER_HOST, MARKETING_PAGE, ONBRAND_PAGE, COLLABORATIONS_PAGE, MESSAGES_PAGE } = window.rewardStyle;

const useNavigationLinks = ($gettext: (msgid: string) => string) => {
  const idTokenClaims = computedAsync(async () => await auth.getIdTokenClaims());

  const country = computed(() => AccountsStore.user.address.country);
  const isUs = computed(() => country.value === 'US');
  const isBr = computed(() => country.value === 'BR');
  const hasCollabsAccess = computed(() => idTokenClaims.value?.application_access?.collabs || false);

  const { hasAboutYouContent } = useAboutYouContent(AccountsStore, ProfilesStore);

  const { useExperiment } = useExperimentsStore();

  const { variant: moneyspotFeatureFlag } = useExperiment(ExperimentName.MONEYSPOT_FEATURE_FLAG, ExperimentType.FLAG);
  const { variant: socialIntFlag } = useExperiment(ExperimentName.SOCIAL_INTEGRATION, ExperimentType.FLAG);

  const MONEYSPOT_FEATURE_FLAG = computed(() => moneyspotFeatureFlag.value.value === 'on');
  const SOCIAL_INTEGRATION_FEATURE_FLAG = computed(() => socialIntFlag.value.value === 'on');

  const accountLinks = computed((): NavigationItem => {
    const account: NavigationItem[] = [
      {
        label: $gettext('Account details'),
        id: uniqueId(),
        to: '/account/account-details',
        show: true,
        children: [
          {
            id: uniqueId(),
            label: $gettext('Personal information'),
            to: '/account/account-details#personal-info',
            show: true,
          },
          {
            id: uniqueId(),
            label: $gettext('About you & your content'),
            to: '/account/account-details#about-you',
            show: hasAboutYouContent.value,
          },
          {
            id: uniqueId(),
            label: $gettext('Social platform info'),
            to: '/account/account-details#social-platform',
            show: userStatus.isAccountAdmin(idTokenClaims.value),
          },
          {
            id: uniqueId(),
            label: $gettext('Social Integration'),
            to: '/account/account-details#social-integration',
            show: userStatus.isAccountAdmin(idTokenClaims.value) && SOCIAL_INTEGRATION_FEATURE_FLAG.value,
          },
        ],
      },
      {
        label: $gettext('Settings'),
        id: uniqueId(),
        to: '/account/settings',
        show: true,
        children: [
          {
            id: uniqueId(),
            label: $gettext('Contributor management'),
            to: '/account/settings#contributors',
            show: userStatus.hasCommissionPermissions(idTokenClaims.value),
          },
          {
            id: uniqueId(),
            label: $gettext('Payment information'),
            to: '/account/settings#payment-info',
            show: userStatus.isAccountAdmin(idTokenClaims.value),
          },
          {
            id: uniqueId(),
            label: $gettext('Change password'),
            to: '/account/settings#change-password',
            show: true,
          },
          {
            id: uniqueId(),
            label: $gettext('Linking'),
            to: '/account/settings#linking',
            show: userStatus.isAccountAdmin(idTokenClaims.value),
          },
        ],
      },
      {
        id: uniqueId(),
        label: $gettext('Preferences'),
        to: '/account/preferences',
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('FAQ & Tech support'),
        href: `https://${HELP_CENTER_HOST}/hc/en-us`,
        openInNewTab: true,
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('Contact us'),
        to: '/account/contact-us',
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('Refer a creator'),
        to: '/apply/invite',
        openInNewTab: true,
        show: true,
      },
    ];

    const links: NavigationItem = {
      label: $gettext('My Account'),
      id: uniqueId(),
      to: '/account',
      show: true,
      children: account,
    };

    return links;
  });

  const productLinks = computed((): NavigationItem => {
    const folders: NavigationItem[] = [
      {
        id: uniqueId(),
        label: $gettext('Recent Products'),
        href: legacyHomePageLink('/products/recent'),
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('My Folders'),
        to: '/products/my-folders',
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('Most Popular'),
        href: legacyHomePageLink('/products/popular'),
        show: true,
      },
      {
        id: uniqueId(),
        label: $gettext('Search'),
        to: '/products/search',
        show: true,
        hint: $gettext('Beta'),
      },
      {
        id: uniqueId(),
        label: $gettext('Brands'),
        href: legacyHomePageLink('/ads/rates'),
        show: true,
      },
    ];

    const links: NavigationItem = {
      label: $gettext('Products'),
      id: uniqueId(),
      breakpoints: {
        drawer: 'xs',
        nav: 'smAndUp',
      },
      to: '/products',
      show: true,
      children: folders,
    };

    return links;
  });

  const insightLinks = computed((): NavigationItem => {
    const insights: NavigationItem = {
      label: $gettext('Insights'),
      id: uniqueId(),
      breakpoints: {
        drawer: 'xs',
        nav: 'smAndUp',
      },
      show: userStatus.hasCommissionPermissions(idTokenClaims.value),
      children: [
        {
          id: uniqueId(),
          label: $gettext('Analytics'),
          to: '/analytics',
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Earnings'),
          href: legacyHomePageLink('/affiliate-rewards'),
          show: userStatus.isAccountAdmin(idTokenClaims.value),
        },
        {
          id: uniqueId(),
          label: $gettext('Referrals'),
          href: legacyHomePageLink('/referrals'),
          show: userStatus.isAccountAdmin(idTokenClaims.value),
        },
      ],
    };
    return insights;
  });

  const toolLinks = computed((): NavigationItem => {
    const tools: NavigationItem = {
      label: $gettext('Tools'),
      id: uniqueId(),
      breakpoints: {
        drawer: 'xs',
        nav: 'smAndUp',
      },
      show: true,
      children: [
        {
          id: uniqueId(),
          label: 'LTK Widget',
          href: legacyHomePageLink('/apps/ltkwidget'),
          show: true,
        },
        {
          id: uniqueId(),
          label: 'Shop The Post',
          href: legacyHomePageLink('/apps/shopthepost'),
          show: true,
        },
        {
          id: uniqueId(),
          label: 'Lookbook',
          to: '/widgets/lookbook',
          show: true,
        },
        {
          id: uniqueId(),
          label: 'Boutique',
          href: legacyHomePageLink('/apps/boutique'),
          show: true,
        },
        {
          id: uniqueId(),
          label: 'Money Spot',
          href: MONEYSPOT_FEATURE_FLAG.value ? undefined : legacyHomePageLink('/apps/moneyspot'),
          to: MONEYSPOT_FEATURE_FLAG.value ? '/widgets/money-spots' : undefined,
          show: true,
        },
        {
          id: uniqueId(),
          label: 'LTK Product Link',
          href: '/ltk-product-link',
          show: true,
        },
        {
          id: uniqueId(),
          label: 'Bulk Media Upload',
          href: '/publish/ltk/bulk-media-upload',
          hint: $gettext('Beta'),
          show: true,
        },
      ],
    };

    return tools;
  });

  const collaborationLinks = computed((): NavigationItem => {
    const collabs: NavigationItem = {
      label: $gettext('Collaborations'),
      id: uniqueId(),
      breakpoints: {
        drawer: 'smAndDown',
        nav: 'mdAndUp',
      },
      show: hasCollabsAccess.value,
      children: [
        {
          id: uniqueId(),
          label: $gettext('Messages'),
          href: `${MESSAGES_PAGE}`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('My collaborations'),
          href: `${COLLABORATIONS_PAGE}/collaborations`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Collaborations profile'),
          href: `${COLLABORATIONS_PAGE}/profile`,
          show: true,
        },
      ],
    };
    return collabs;
  });

  // Old Resource center, available to users outside the
  const resourceLinks = computed((): NavigationItem => {
    const resources: NavigationItem = {
      label: $gettext('Resources'),
      id: uniqueId(),
      breakpoints: {
        drawer: 'smAndDown',
        nav: 'mdAndUp',
      },
      show: !isUs.value,
      children: [
        {
          id: uniqueId(),
          label: $gettext('My Strategy'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/strategy-activation`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Resource Center'),
          href: `${MARKETING_PAGE}/us/creatorsuccess/resource-center`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Getting Started') /* Getting started with LTK creator */,
          href: `${MARKETING_PAGE}/us/influencergrowthservices/getting-started`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Brand Updates'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/whats-on-sale`,
          show: !isBr.value,
        },
      ],
    };
    return resources;
  });

  // LTK EDU is US only
  const ltkEdu = computed((): NavigationItem => {
    const resources: NavigationItem = {
      label: 'LTK Edu',
      id: uniqueId(),
      breakpoints: {
        drawer: 'smAndDown',
        nav: 'mdAndUp',
      },
      show: isUs.value,
      children: [
        {
          id: uniqueId(),
          label: $gettext('Get started') /* Getting started with LTK creator */,
          href: `${MARKETING_PAGE}/us/influencergrowthservices/getting-started`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Grow your earnings') /* tips for making more earnings/sales */,
          href: `${MARKETING_PAGE}/us/creatorsuccess/ltkedu`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('New LTK features'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/ltkfeatures`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Content planning hub'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/content-planning-hub`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Virtual education') /* online education and tools */,
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/virtualeducation`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext("What's on sale") /* Updates and sale items from brands */,
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/whats-on-sale`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('New brands on LTK'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/newtoltk2023?hsCtaTracking=b9fdd3af-0a45-4b84-b58c-ab1aa0015d63%7C9e3bf8c7-b92b-46a2-862f-4b6d74741c50`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Help center'),
          href: `https://${HELP_CENTER_HOST}/hc/en-us?__hsfp=3958346737&__hssc=148330984.1.1722455947290&__hstc=148330984.8e915e971cf71c3737700d723a2a644f.1710258641388.1722378333900.1722455947290.325`,
          show: true,
        },
        {
          id: uniqueId(),
          label: $gettext('Status & outages'),
          href: `${MARKETING_PAGE}/us/influencergrowthservices/tools/status`,
          show: true,
        },
      ],
    };
    return resources;
  });

  const siteNavigation = computed((): NavigationItem[] => [
    productLinks.value,
    insightLinks.value,
    toolLinks.value,
    collaborationLinks.value,
    resourceLinks.value,
    ltkEdu.value,
  ]);

  return {
    accountLinks,
    productLinks,
    insightLinks,
    toolLinks,
    resourceLinks,
    collaborationLinks,
    siteNavigation,
  };
};

export default useNavigationLinks;
