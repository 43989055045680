import { RouteConfig } from 'vue-router';
import { ACCOUNT, ACCOUNT_DETAILS, ACCOUNT_SETTINGS } from '@/utils/router/namedRoutes';
import { validateRoute } from '@/router/navigation-guards/beforeEnter/validateRoute';
import LeavePagePopupLayout from '@/components/LeavePagePopupLayout.vue';
import { uniqueId } from '@/utils/uniqueId';
import { gettext } from '@/utils/fakeGetText';

import Profiles from '@/rs-account/views/Profiles.vue';

import { FeatureFlags, isFlagActive } from '@/utils/feature-flags';

const LTK_SHOPS = isFlagActive(FeatureFlags.LTK_SHOPS);

/*
  Documentation of using gettext without Vue: https://github.com/Polyconseil/vue-gettext#usage-of-translate-without-vue
  Note: Gives warning of no exported member
*/

const routes: RouteConfig[] = [
  {
    path: '/account',
    beforeEnter: async (to, from, next) => {
      await validateRoute(['disabled'], to, from, next);
    },
    component: () => import(/* webpackChunkName: "account" */ '../AccountShell.vue'),
    children: [
      {
        path: '',
        redirect: '/account/account-details',
        name: ACCOUNT,
      },
      {
        path: 'account-details',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_DETAILS,
            component: () => import(/* webpackChunkName: "account-details" */ '../views/AccountDetails.vue'),
            meta: {
              breadcrumbs: [
                { label: gettext('Account'), to: '/account', id: uniqueId() },
                { label: gettext('Account details'), to: '/account/account-details', id: uniqueId() },
              ],
            },
          },
        ],
      },
      {
        path: 'my-ltk-shops',
        beforeEnter: (to, from, next) => {
          if (!LTK_SHOPS) next({ name: ACCOUNT });
          else next();
        },
        component: () => import(/* webpackChunkName: "ltk shops" */ '../views/LTKShops.vue'),
        meta: {
          breadcrumbs: [
            { label: gettext('Account'), to: '/account', id: uniqueId() },
            { label: gettext('My LTK Shops'), to: '/account/my-ltk-shops', id: uniqueId() },
          ],
        },
      },
      {
        path: 'settings',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            name: ACCOUNT_SETTINGS,
            component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
            meta: {
              breadcrumbs: [
                { label: gettext('Account'), to: '/account', id: uniqueId() },
                { label: gettext('Settings'), to: '/account/settings', id: uniqueId() },
              ],
            },
          },
        ],
      },
      {
        path: 'contact-us',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "contact us" */ '../views/ContactUs.vue'),
            meta: {
              breadcrumbs: [
                { label: gettext('Account'), to: '/account', id: uniqueId() },
                { label: gettext('Contact us'), to: '/account/contact-us', id: uniqueId() },
              ],
            },
          },
        ],
      },
      {
        path: 'preferences',
        component: LeavePagePopupLayout,
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "email preferences" */ '../views/EmailPreferences.vue'),
            meta: {
              breadcrumbs: [
                { label: gettext('Account'), to: '/account', id: uniqueId() },
                { label: gettext('Preferences'), to: '/account/preferences', id: uniqueId() },
              ],
            },
          },
        ],
      },
    ],
  },
];

export default routes;
