import { Route } from 'vue-router';
import { isLegalRoute, isSetupRoute } from '@/utils/router/namedRoutes';
import { ModeOptions, getMode } from '@/rs-account-setup/composables/useAccountSetupWizard';

/**
 * Utility for determining whether a redirection is needed to the Account Setup area of our web application.
 * @param to - the current route
 * @param options - the current user state
 * @returns boolean
 */
export function redirectToAccountSetup(to: Route, options: ModeOptions): boolean {
  if (isLegalRoute(to)) return false;
  if (isSetupRoute(to)) return false;

  const mode = getMode(options);

  switch (mode) {
    // Do not redirect on "Accept Updated Terms", we have a separate user flow with the TosUpdateModal and our AppModalDrawer for that.
    case 'ACCEPT_UPDATED_TERMS':
    case 'DEFAULT':
      return false;
    default:
      return true;
  }
}
