import _Vue from 'vue';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

// https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm#sampling
const sampleAll = 100;
const sampleLimitedProd = 1;

export default (Vue: typeof _Vue) => {
  const datadogConfig = window.rewardStyle.DATADOG;
  if (!datadogConfig) {
    return;
  }

  const env = window.rewardStyle.ENV;
  const version = process.env.VUE_APP_VERSION;

  const { clientToken, service, site, applicationId } = datadogConfig;
  const sessionSampleRate = env === 'prod' ? sampleLimitedProd : sampleAll;

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service,
    env,
    version,
    trackViewsManually: true,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    sessionSampleRate,
    sessionReplaySampleRate: sessionSampleRate,
    // Need to expand CORS config headers from tyk and other sources before enabling tracing origins
    // access-control-request-headers: authorization,content-type,x-datadog-origin,x-datadog-parent-id,x-datadog-sampled,x-datadog-sampling-priority,x-datadog-trace-id
    allowedTracingUrls: [
      { match: /https?:\/\/.*\.rewardstyle\.com/, propagatorTypes: ['datadog'] },
      { match: /https?:\/\/.*\.liketoknow\.it/, propagatorTypes: ['datadog'] },
      { match: /https?:\/\/(?!login\.).*\.shopltk\.com/, propagatorTypes: ['datadog'] },
      { match: /https?:\/\/(?!login\.).*\.qashopltk\.com/, propagatorTypes: ['datadog'] },
    ],
  });

  datadogRum.startSessionReplayRecording();

  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    version,
    sessionSampleRate,
  });
};
