export const _dataUriToBlob = (dataUri: string): Blob | null => {
  const matches = dataUri.match(/^data:(.*);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    return null;
  }
  const base64Data = matches[2];
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: 'image/jpeg' });
};

export const _dataUriToFile = (dataUri: string, fileName: string): File | null => {
  const blob = _dataUriToBlob(dataUri);

  if (!blob) {
    return null;
  }

  return new File([blob], fileName, { type: blob.type });
};

export const _videoFileBlobJPEG = (videoFile: File, seekTime: number = 0): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      reject(new Error('Could not get canvas context'));
      return;
    }
    video.src = URL.createObjectURL(videoFile);
    video.preload = 'metadata';

    video.addEventListener('loadedmetadata', () => {
      video.currentTime = seekTime;
    });

    video.addEventListener('seeked', () => {
      setTimeout(() => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Could not convert canvas to blob'));
          }
        }, 'image/jpeg');
      }, 200);
    });

    video.addEventListener('error', (err) => {
      reject(new Error('Error loading video'));
    });
  });
};
