import type { RouteConfig } from 'vue-router';
import { ExperimentName, ExperimentType } from '@/types/experiments';
import type { ExperimentMeta } from '@/types/experiments';
import { DESKTOP_PUBLISH, DESKTOP_CAPTION } from '@/utils/router/namedRoutes';
import { Layout } from '@/layouts/types';

const experimentMeta: ExperimentMeta = {
  name: ExperimentName.DESKTOP_PUBLISH,
  type: ExperimentType.FLAG,
  allowed: ['on'],
};

const routes: RouteConfig[] = [
  {
    name: DESKTOP_PUBLISH,
    path: '/desktop-publish',
    component: () => import(/* webpackChunkName: "desktop-publish" */ '../views/DesktopPublish.vue'),
    meta: {
      experiment: experimentMeta,
    },
  },
];

export default routes;
