import { Auth, AuthOptions } from '@rscollabs/rs-ui-auth';

const buildAuthConfig = () => {
  const opts = window.rewardStyle;

  const authConfig: AuthOptions = {
    sigil: {
      idTokenCookie: opts?.ID_TOKEN_COOKIE || 'sigil_id_token',
      accessTokenCookie: opts?.ACCESS_TOKEN_COOKIE || 'sigil_access_token',
      loginUrl: opts?.SIGIL_URL,
      logoutUrl: opts?.LEGACY_HOME_PAGE,
    },
  };

  if (opts?.AUTH0) {
    authConfig.auth0 = {
      clientOptions: {
        clientId: opts.AUTH0.clientId,
        domain: opts.AUTH0.domain,
        authorizationParams: {
          audience: opts.AUTH0.audience,
          redirect_uri: opts.AUTH0.redirectUri,
          scope: 'openid profile email ltk.publisher',
        },
        cacheLocation: 'localstorage',
        useCookiesForTransactions: true,
        useRefreshTokens: true,
      },
      // Called after successful code exchange
      redirectCallback: (target: string) => {
        window.location.replace(target);
      },
    };
  }

  return authConfig;
};

export const auth = new Auth(buildAuthConfig());
