import { UserInfoState, UserInfo, UserInfoResponse } from '@/store/userInfo/types';
import { ApiError } from '@/utils/api';
import Vue from 'vue';
import { Module, VuexModule, Action, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import UserInfoApi from '@/store/userInfo/api';
import { Currency } from '@/utils/currency';

@Module({ dynamic: true, name: 'UserInformation', store })
class AppUserInfo extends VuexModule implements UserInfoState {
  public userInfo: UserInfo = {
    exchange_rate: 0,
    local_symbol: '',
    locale: 'en_US',
    intl_currency_symbol: Currency.USD,
    bitly: false,
    user_name: '',
    user_email: '',
    region_id: '',
    user_id: '',
    account_id: '',
    has_completed_onboarding: true,
    require_password_change: false,
  };

  public hasFetchedUserInfo = false;

  public errorHeader = '';
  public unauthorized = false;
  public serverError: ApiError | null = null;

  /**
   * @description Fetch user info
   * @param {Object} options - Options object
   * @param {boolean} options.overwrite - Determines whether the action fetches and overwrites the data. Set to false (cache data from first action call) by default
   */
  @Action
  public async fetchUserInfo({ overwrite = false }) {
    const canOverwrite = overwrite || !this.hasFetchedUserInfo;
    try {
      if (canOverwrite) {
        this.FETCH_USER_INFO(await UserInfoApi.get());
        this.CLEAR_ERRORS();
        this.HAS_FETCHED_USER_INFO(true);
      }
    } catch (error) {
      this.FETCH_ERROR_HEADER();
      this.FETCH_AUTHORIZATION_ERROR();
      this.USER_INFO_SERVER_ERROR(error as ApiError);
    }
  }

  @Mutation
  public FETCH_USER_INFO(userInfo: UserInfoResponse) {
    this.userInfo.exchange_rate = parseFloat(userInfo.region.exchange_rate);
    this.userInfo.local_symbol = userInfo.region.symbol;
    this.userInfo.locale = userInfo.region.locale;
    this.userInfo.intl_currency_symbol = userInfo.region.currency as Currency.USD;
    this.userInfo.bitly = parseInt(userInfo.bitly, 10) === 1;
    this.userInfo.user_name = userInfo.user_name;
    this.userInfo.user_email = userInfo.user_email;
    this.userInfo.region_id = userInfo.region_id;
    this.userInfo.user_id = userInfo.push_settings.user_id;
    this.userInfo.account_id = userInfo.account_id;
    this.userInfo.has_completed_onboarding = userInfo.has_completed_onboarding;
    this.userInfo.require_password_change = userInfo.require_password_change;
  }

  @Mutation
  public HAS_FETCHED_USER_INFO(hasFetchedUserInfo: boolean) {
    this.hasFetchedUserInfo = hasFetchedUserInfo;
  }

  @Mutation
  public FETCH_ERROR_HEADER() {
    this.errorHeader = UserInfoApi.header;
  }

  @Mutation
  public FETCH_AUTHORIZATION_ERROR() {
    this.unauthorized = UserInfoApi.unauthorized;
  }

  @Mutation
  public USER_INFO_SERVER_ERROR(error: ApiError | null) {
    Vue.set(this, 'serverError', error);
  }

  @Mutation
  public CLEAR_ERRORS() {
    this.errorHeader = '';
    this.unauthorized = false;
    Vue.set(this, 'serverError', null);
  }
}

export default getModule(AppUserInfo);
