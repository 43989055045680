export enum ErrorCode {
  TOKEN_EXPIRED = 'token-expired',
  BAD_REQUEST = 'bad-request',
  DUPLICATE_EMAIL = 'duplicate-email',
  ACCOUNT_ALREADY_EXISTS = 'account-already-exists',
  NO_PROMOTIONS_FOUND = 'no-promotions-found',
  DUPLICATE_DISPLAY_NAME = 'duplicate-display-name',
  CAPTCHA_ERROR = 'captcha-error',
  INVALID_REQUEST_BODY = 'invalid-request-body',
}
